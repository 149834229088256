
import './App.css';
import CreatePoll from './components/pages/createPoll';
import Poll from './components/pages/poll';
import Error from './components/pages/error';
import { BrowserRouter, Navigate, Routes, Route } from "react-router-dom";
import Results from './components/pages/results';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Navigate to="/create" />} />
        <Route exact path="/create" element={<CreatePoll />} />
        <Route exact path="/poll/:id" element={<Poll />} />
        <Route exact path="/result/:id" element={<Results />} />
        <Route exact path="*" element={<Error />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
