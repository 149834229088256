import React, { useEffect, useState } from 'react'
import { ActiveRadioButton, CheckBoxActiveIcon, ClipboardIcon, FacebookIcon, InactiveRadioButton, LoaderIcon, RedditIcon, TelegramIcon, TwitterIcon, WhatsappIcon } from '../icons'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import img from "../images/logoWithBackground.png"
import Footer from './footer';
import CryptoJS from 'crypto-js';
import { DeviceUUID } from 'device-uuid';
import { useRef } from 'react';

const Poll = () => {
    const [data, setData] = useState({
        title: "Title",
        description: "This is description ",
        imgtitle: "",
        options: [],
        polltype: "imagePoll",
        multipleoption: { "type": "range" },
        imageoptions: [],
        setenddate: "",
        votingrestiction: "One vote per IP address",
        allowcomments: true,
        startnumber: 0,
        endnumber: 1
    })

    const navigate = useNavigate()

    const [comment, setComment] = useState("");
    const [answer, setAnswer] = useState([]);
    const [answerImage, setAnswerImage] = useState([{ image: "", text: "" }]);
    const [error, setError] = useState("");
    const [load, setLoad] = useState(false);
    const [load1, setLoad1] = useState(false);
    const [copied, setCopied] = useState(false);
    const [URLShare, setURLShare] = useState('');
    const [URLShareLink, setURLShareLink] = useState('');
    const adsbygoogle = useRef("");
    // const [latest5, setLatest5] = useState([]);

    useEffect(() => {
        setTimeout(() => {
            setCopied(false);
        }, 2000);
    }, [copied])

    useEffect(() => {
        setURLShare(`*${data.title}*%0a%0aLink: ${encodeURIComponent(window.location.href)}%0a%0a*Vote more, Share More!* %0a%0aThank You`);
        setURLShareLink(encodeURIComponent(window.location.href));
    }, [data])


    const chechExistOption = (opt) => {
        for (let i = 0; i < answer.length; i++) {
            if (answer[i] === opt) {
                return true;
            }
        }
        return false
    }

    const chechExistImageOption = (opt) => {
        for (let i = 0; i < answerImage.length; i++) {
            if (answerImage[i].text === opt.text) {
                return true;
            }
        }
        return false
    }

    const handleOption = (opt) => {
        var flag = false;
        for (let i = 0; i < answer.length; i++) {
            if (answer[i] === opt) {
                flag = true;
                break
            }
        }
        if (!flag) {
            setAnswer([...answer, opt])
        } else {
            setAnswer(answer.filter((ans) => (ans !== opt)))
        }
    }

    const handleImageOption = (opt) => {
        var flag = false;
        for (let i = 0; i < answerImage.length; i++) {
            if (answerImage[i].text === opt.text) {
                flag = true;
                break
            }
        }
        if (!flag) {
            setAnswerImage([...answerImage, opt])
        } else {
            setAnswerImage(answerImage.filter((ans) => (ans.text !== opt.text && ans.text !== "")))
        }
    }

    const handleSubmit = async () => {
        var uuid = new DeviceUUID().get();
        var temp = answerImage.filter((ans) => (ans.text !== ""))
        if (data.polltype === "multipleOption") {
            if (data.multipleoption.type === "Exact number") {
                if (answer.length !== data.number) {
                    setError("please select " + data.number + " options")
                    return
                }
            } else if (data.multipleoption.type === "range") {
                if (answer.length < data.startnumber || answer.length > data.endnumber) {
                    setError("please select options in range of " + data.startnumber + " and " + data.endnumber)
                    return
                }
            } else {
                if (answer.length < 1) {
                    setError("please select an option")
                    return
                }
            }
        }
        if (data.polltype === "imagePoll") {
            if (data.multipleoption.type === "Exact number") {
                if (temp.length !== data.number) {
                    setError("please select " + data.number + " options")
                    return
                }
            } else if (data.multipleoption.type === "range") {
                if (temp.length < data.startnumber || temp.length > data.endnumber) {
                    setError("please select options in range of " + data.startnumber + " and " + data.endnumber)
                    return
                }
            } else {
                if (temp.length < 1) {
                    setError("please select an option")
                    return
                }
            }
        }

        if (data.setenddate !== false) {
            var currDate = new Date();
            var tempDt = (`${data.setenddate.split('T')[0].split('-')[0]}/${data.setenddate.split('T')[0].split('-')[1]}/${data.setenddate.split('T')[0].split('-')[2]}/${data.setenddate.split('T')[1].split(':')[0]}/${data.setenddate.split('T')[1].split(':')[1]}/00`).split('/');
            var eDate = new Date(tempDt[0], tempDt[1] - 1, tempDt[2], tempDt[3], tempDt[4]);
            if (currDate >= eDate) {
                setError("You cannot vote now, the poll has been ended.");
                return;
            }
        }



        setLoad(true);

        // axios.get('https://geolocation-db.com/json/')
        //     .then((res) => { 
        //     })
        // console.log(await internalIpV4())
        // .then((res) =>{
        //     console.log(res)
        // })

        // var ipAddress;
        // var RTCPeerConnection = window.webkitRTCPeerConnection || window.mozRTCPeerConnection;
        // if(RTCPeerConnection) (function() {

        //     var rtc = new RTCPeerConnection({ iceServers: [] });
        //     rtc.createDataChannel('', { reliable: false });

        //     rtc.onicecandidate = function(evt) {
        //         if(evt.candidate) grepSDE(evt.candidate.candidate);
        //     }

        //     rtc.createOffer(function(offerDesc) {
        //         rtc.setLocalDescription(offerDesc);
        //     }, function(e) { console.warn("failed to get ip adress ", e); });

        //     function grepSDE(sdp) {
        //         var ip = /(192\.168\.(0|\d{0,3})\.(0|\d{0,3}))/i;
        //         sdp.split('\r\n').forEach(function(line) {
        //             if(line.match(ip)) {
        //                 ipAddress = line.match(ip)[0];
        //                 console.log(ipAddress);
        //             }
        //         })
        //     }
        // })();

        // const networkInterfaces = os.networkInterfaces();
        // const ip = networkInterfaces['eth0'][0]['address']

        // console.log(networkInterfaces, ip);



        var dt = {
            macaddr: uuid,
            pollid: window.location.href.split("/")[4],
            choices: (data.polltype === "multipleOption" ? answer : temp),
        }
        var key = process.env.REACT_APP_KEY
        key = CryptoJS.enc.Utf8.parse(key);
        var encrypted = CryptoJS.AES.encrypt(JSON.stringify(dt), key, { mode: CryptoJS.mode.ECB });
        encrypted = encrypted.toString();

        axios.post("https://backend.bestkaun.com/poll/reply", { ...dt, key: encrypted })
            .then((res) => {
                setLoad(false);
                navigate('/result/' + window.location.href.split("/")[4]);
            })
            .catch((err) => {
                if (err.response.data === "you can't vote right now") {
                    setError("You have already voted");
                    setLoad(false);
                }
                else {
                    console.log(err)
                    // navigate('/');
                }
            })

    }
    useEffect(() => {
        setLoad1(true);
        axios.post("https://backend.bestkaun.com/poll/detail/" + window.location.href.split("/")[4])
            .then((res) => {
                // if (res.data == null) {
                //     navigate('/');
                // }
                setData(res.data);
                // document.getElementById("description").setAttribute("content", res.data.title);
                setLoad1(false);
            })
            .catch((err) => {
                setLoad1(false);
                navigate('/');
            })

        // axios.post("https://backend.bestkaun.com/poll/latest/", { id : window.location.href.split("/")[4] })
        //     .then((res) => {
        //         if (res.data == null) {
        //             navigate('/');
        //         }
        //         setLatest5(res.data);
        //     })
        //     .catch((err) => {
        //         navigate('/');
        //     })
    }, [navigate])

    useEffect(() => {
        // const script = document.createElement('script');
        // script.src = "https://jsc.adskeeper.com/b/e/bestkaun.com.1357850.js";
        // script.async = true;
        // // script.type = "text/jsx";
        // script.defer = true;

        // let ad = document.getElementById('ad');
        // ad.appendChild(script);

        const script2 = document.createElement('script');
        script2.src = "https://jsc.adskeeper.com/b/e/bestkaun.com.1357848.js";
        script2.async = true;
        // script.type = "text/jsx";
        script2.defer = true;

        let ad2 = document.getElementById('ad2');
        ad2.appendChild(script2);

        

        if(document.getElementById("gad1").children.length===0){
            (adsbygoogle.current = window.adsbygoogle || []).push({});
        }

        // return (() => {
        //     document.getElementById('ad').removeChild(script);
        // })
    }, [data])

    return (
        <>
            {load1 ? (
                <div className='relative bg-background1 h-full w-full'>
                    <div className='w-32 h-32 absolute top-1/2 left-1/2 mt-36'> <LoaderIcon /> </div>
                </div>
            ) : (
                <>



                        <div>

                    <div className='w-full md:w-1/2 mx-auto mt-6'>
                        <div className='flex items-center justify-center mb-4 cursor-pointer'>
                            <div className='w-20 h-20' onClick={() => navigate('/')}>
                                <img src={img} alt="" />
                            </div>
                        </div>

                        <ins className="adsbygoogle" id='gad1'
                        style={{ display: "block" }}
                        data-ad-client="ca-pub-6010927386409234"
                        data-ad-slot="2300270527"
                        data-ad-format="auto"
                        data-full-width-responsive="true"></ins>

                        {/* <div id='ad'>
                            <div id="M829850ScriptRootC1357850">
                            </div>
                        </div> */}
                        <Helmet>
                            <title>{data.title}</title>
                        </Helmet>
                        <div className='text-center mt-8'>
                            <h1 className="text-2xl font-bold leading-7 sm:text-3xl sm:truncate text-white">
                                Poll
                            </h1>
                            <p className="mt-2 text-sm text-gray-500">
                                Provide your response and submit your opinion
                            </p>
                        </div>
                        <div className='bg-background2 border-t-4 mt-8 border-border1 rounded p-6'>
                            <div>

                                <h1 className='text-2xl'>{data.title}</h1>
                                <p className='text-base text-text1 mt-2'>{data.description}</p>

                                <div>
                                    {data.imgtitle ? (
                                        <div className='mt-6'>
                                            <img src={data.imgtitle} className='w-36' alt="" />
                                        </div>
                                    ) : <></>}
                                </div>
                                <div>
                                    <p className='text-base text-text1 mt-4'>Make a choice {data.multipleoption.type === "None" ? <></> : <span>(Multiple{data.multipleoption.type === "Exact number" ? (': ' + data.number) : (data.multipleoption.type === "unlimited" ? "" : (data.startnumber + '-' + data.endnumber))})</span>} :</p>
                                </div>
                                <div>
                                    {data.polltype === "multipleOption" ? (
                                        (data.multipleoption.type === "None" ? (
                                            <div>
                                                <div>
                                                    {
                                                        data.options.map((opt) => {
                                                            return (
                                                                <div>
                                                                    <div className='p-2'>
                                                                        <div
                                                                            className='flex gap-3 items-center cursor-pointer'
                                                                            onClick={() => setAnswer([opt])}
                                                                        >
                                                                            {(answer[0] === opt) ? <ActiveRadioButton /> : <InactiveRadioButton />}
                                                                            <p className='text-base'>{opt}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        ) : (
                                            <div>
                                                <div>
                                                    {
                                                        data.options.map((opt) => {
                                                            return (
                                                                <div>
                                                                    <div className='p-2'>
                                                                        <div
                                                                            className='flex gap-3 items-center'
                                                                            onClick={() => handleOption(opt)}
                                                                        >
                                                                            {chechExistOption(opt) ? <CheckBoxActiveIcon /> : <div className='p-0.5'><div className='border border-indigo-600 rounded-full' style={{ padding: "9px" }}></div></div>}
                                                                            <p className='text-base'>{opt}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        (data.multipleoption.type === "None" ? (
                                            <div>
                                                <div className='grid grid-cols-2 gap-6'>
                                                    {
                                                        data.imageoptions.map((opt) => {
                                                            return (
                                                                <div
                                                                    className={'border rounded cursor-pointer ' + (answerImage[0].text === opt.text ? "border-indigo-600" : "border-border2")}
                                                                    onClick={() => setAnswerImage([opt])}
                                                                >
                                                                    <div className='flex justify-center items-center p-1'>
                                                                        <img src={opt.image} style={{ height: "138px" }} alt="" />
                                                                    </div>
                                                                    <div
                                                                        className={'p-2 border-t ' + (answerImage[0].text === opt.text ? "border-indigo-600" : "border-border2")}
                                                                    >
                                                                        <div className='flex gap-3 items-center'>
                                                                            {answerImage[0].text === opt.text ? <ActiveRadioButton /> : <div className='p-0.5'><div className='border border-border2 rounded-full' style={{ padding: "9px" }}></div></div>}
                                                                            <p className='text-base'>{opt.text}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        ) : (
                                            <div>
                                                <div className='grid grid-cols-2 gap-6'>
                                                    {
                                                        data.imageoptions.map((opt) => {
                                                            return (
                                                                <div
                                                                    className={'border rounded cursor-pointer ' + (chechExistImageOption(opt) ? "border-indigo-600" : "border-border2")}
                                                                    onClick={() => handleImageOption(opt)}
                                                                >
                                                                    <div className='flex justify-center items-center p-1'>
                                                                        <img src={opt.image} style={{ height: "138px" }} alt="" />
                                                                    </div>
                                                                    <div
                                                                        className={'p-2 border-t ' + (chechExistImageOption(opt) ? "border-indigo-600" : "border-border2")}
                                                                    >
                                                                        <div className='flex gap-3 items-center'>
                                                                            {chechExistImageOption(opt) ? <CheckBoxActiveIcon /> : <div className='p-0.5'><div className='border border-border2 rounded-full' style={{ padding: "9px" }}></div></div>}
                                                                            <p className='text-base'>{opt.text}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        ))
                                    )}
                                </div>
                            </div>

                            {
                                data.allowcomments ? (
                                    <div>
                                        <div className='mt-4'>
                                            <p>Comment</p>
                                            <input
                                                className='mt-2 text-sm bg-inputBackground w-full rounded-md px-2 py-2 outline-none border border-transparent focus:border focus:border-indigo-600'
                                                type="text"
                                                value={comment}
                                                onChange={(e) => setComment(e.target.value)}
                                                placeholder='Comment'
                                            />
                                        </div>
                                    </div>
                                ) : <></>
                            }

                            <div className='mt-4 text-base text-red-400'>
                                <h3>{error}</h3>
                            </div>

                            <div className='flex items-center gap-4'>
                                <button
                                    className='py-2 w-4/12 px-4 mt-6 bg-indigo-600 rounded-md'
                                    onClick={handleSubmit}
                                    disabled={load}
                                >
                                    {load ? <div className='w-8 h-8'> <LoaderIcon /> </div> : <>Vote</>}
                                </button>

                                <button
                                    className='py-2 w-4/12 px-4 mt-6 bg-border2 rounded-md'
                                    onClick={() => {
                                        navigate('/result/' + window.location.href.split("/")[4])
                                    }}
                                >
                                    Result
                                </button>
                            </div>
                        </div>


                        <div>

                            <div>
                                <h1 className='text-2xl mt-6'>Share</h1>
                            </div>
                            <div
                                className='relative mt-2'
                            >
                                <p
                                    className='bg-inputBackground overflow-hidden text-text1 rounded-md pl-4 py-3 text-base outline-none w-full pr-12 border border-transparent focus:border focus:border-indigo-600'
                                    style={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                                >
                                    {window.location.href}
                                </p>

                                <div
                                    className="absolute cursor-pointer inset-y-0 right-5 pl-3 flex items-center "
                                    onClick={() => {
                                        navigator.clipboard.writeText(window.location.href)
                                        setCopied(true)
                                    }}
                                >
                                    {copied ? <p className='text-text1'>Copied</p> : <ClipboardIcon />}
                                </div>
                            </div>

                            <div className='flex gap-2 justify-center items-center mt-4'>
                                <a href={("https://api.whatsapp.com/send?text=" + URLShare)}>
                                    <WhatsappIcon />
                                </a>
                                <a href={("https://www.facebook.com/sharer.php?u=" + URLShareLink)}>
                                    <FacebookIcon />
                                </a>
                                <a href={('https://twitter.com/share?url=' + URLShare)}>
                                    <TwitterIcon />
                                </a>
                                <a href={("https://telegram.me/share/url?url=" + URLShare)}>
                                    <TelegramIcon />
                                </a>
                                <a href={('https://reddit.com/submit?url=' + URLShareLink)}>
                                    <RedditIcon />
                                </a>
                            </div>
                            <div id='ad2' className='mt-6'>
                                <div id="M829850ScriptRootC1357848">
                                </div>
                                {/* <script src="https://jsc.adskeeper.com/b/e/bestkaun.com.1357848.js" async>
                            </script> */}
                            </div>

                            <div className='flex gap-4 items-center justify-center mt-8 mb-6'>
                                <button
                                    className='py-2 px-16 mt-6 bg-indigo-600 rounded-md'
                                    onClick={() => navigate('/create')}
                                >
                                    Create New Poll
                                </button>
                            </div>

                            {/* <div>
                            <h1 className='text-2xl mt-6'>Latest polls</h1>
                            <div>
                                {latest5.map((latest) => {
                                    return (
                                        <div
                                            className='mt-4 cursor-pointer'
                                            onClick={() => {
                                                window.location.href = `${window.location.href.split('/')[0]}${window.location.href.split('/')[1]}//${window.location.href.split('/')[2]}/poll/${latest._id}`
                                            }}
                                        >
                                            <p
                                                className='bg-inputBackground overflow-hidden rounded-md pl-4 py-3 text-base outline-none pr-12 border border-transparent'
                                                style={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                                            >
                                                {latest.title}
                                            </p>
                                        </div>
                                    )
                                })}
                            </div>
                        </div> */}
                        </div>

                    </div>
                    <div>
                        <Footer />
                    </div>
                </div>
                </>
    )
}
        </>

    )
}

export default Poll
