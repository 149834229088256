import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import { ClipboardIcon, FacebookIcon, LoaderIcon, RedditIcon, TelegramIcon, TwitterIcon, WhatsappIcon } from '../icons';
import img from "../images/logoWithBackground.png"
import Footer from './footer';

const Results = () => {
    const [data, setData] = useState({
        polltype: "multipleOption",
        totalcount: 2,
        result_val: []
    })
    const navigate = useNavigate()
    const [load, setLoad] = useState(false);
    const [copied, setCopied] = useState(false);
    const [URLShare, setURLShare] = useState('');
    const [URLShareLink, setURLShareLink] = useState('');
    // const [latest5, setLatest5] = useState([]);

    useEffect(() => {
        setTimeout(() => {
            setCopied(false);
        }, 2000);
    }, [copied])

    useEffect(() => {
        var url = `${window.location.href.split('/')[0]}${window.location.href.split('/')[1]}//${window.location.href.split('/')[2]}/poll/${window.location.href.split('/')[4]}`
        setURLShare(`*${data.polltitle}*%0a%0aLink: ${encodeURIComponent(url)}%0a%0a*Vote more, Share More!* %0a%0aThank You`);
        setURLShareLink(encodeURIComponent(`${window.location.href.split('/')[0]}${window.location.href.split('/')[1]}//${window.location.href.split('/')[2]}/poll/${window.location.href.split('/')[4]}`));
    }, [data])


    useEffect(() => {
        setLoad(true)
        axios.post("https://backend.bestkaun.com/poll/result/" + window.location.href.split("/")[4])
            .then((res) => {
                if (res.data == null) {
                    navigate('/');
                }
                setData(res.data.data);
                setLoad(false);
            })
            .catch((err) => {
                setLoad(false);
                navigate('/');
            })

        // axios.post("https://backend.bestkaun.com/poll/latest/", { id: window.location.href.split("/")[4] })
        //     .then((res) => {
        //         if (res.data == null) {
        //             navigate('/');
        //         }
        //         setLatest5(res.data);
        //     })
        //     .catch((err) => {
        //         navigate('/');
        //     })


    }, [navigate])


    const COLORS = ['#D0F801', '#FF94C6', '#FFBF65', '#32C133', '#00CDAC', '#E1FF9D', '#00B0BB', '#FF5768', '#C15680', '#B0D236'];

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };


    // var loadScript = function (src) {
    //     var tag = document.createElement('script');
    //     tag.async = false;
    //     tag.src = src;
    //     document.getElementsByTagName('body').appendChild(tag);
    // }
    // loadScript('https://jsc.adskeeper.com/b/e/bestkaun.com.1357850.js')

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://jsc.adskeeper.com/b/e/bestkaun.com.1357850.js";
        script.async = true;
        // script.type = "text/jsx";
        script.defer = true;

        let ad = document.getElementById('ad');
        ad.appendChild(script);

        const script2 = document.createElement('script');
        script2.src = "https://jsc.adskeeper.com/b/e/bestkaun.com.1357848.js";
        script2.async = true;
        // script.type = "text/jsx";
        script2.defer = true;

        let ad2 = document.getElementById('ad2');
        ad2.appendChild(script2);

        // return (() => {
        //     document.getElementById('ad').removeChild(script);
        // })
    }, [data])

    return (
        <>
            {load ? (
                <div className='relative bg-background1 h-full w-full'>
                    <div className='w-32 h-32 absolute top-1/2 left-1/2 mt-36'> <LoaderIcon /> </div>
                </div>
            ) : (
                <>
                    <div>
                        <div className='w-full md:w-1/2 mx-auto mt-6'>
                            <div className='flex items-center justify-center mb-4 cursor-pointer'>
                                <div className='w-20 h-20' onClick={() => navigate('/')}>
                                    <img src={img} alt="" />
                                </div>
                            </div>

                            <div id='ad'>
                                <div id="M829850ScriptRootC1357850">
                                </div>
                                {/* <script src="https://jsc.adskeeper.com/b/e/bestkaun.com.1357850.js" async >
                    </script> */}

                            </div>

                            <Helmet>
                                <title>{data.polltitle}</title>
                                <meta name='description' content={data.polltitle} />
                                <meta property="og:description" content={data.polltitle} />
                            </Helmet>
                            <div className='text-center mt-8'>
                                <h1 className="text-2xl font-bold leading-7 sm:text-3xl sm:truncate text-white">
                                    Result
                                </h1>
                            </div>

                            <div className='bg-background2 border-t-4 mt-8 border-border1 rounded p-6'>
                                {data.totalcount > 0 ? (
                                    <div>
                                        <div>
                                            <h1 className='text-2xl'>{data.polltitle}</h1>
                                            <p className='text-base text-text1 mt-2'>{data.polldescription}</p>
                                        </div>


                                        <div className='' style={{ width: '100%', height: 250 }}>
                                            <ResponsiveContainer>
                                                <PieChart>
                                                    <Pie
                                                        data={
                                                            data.result_val.map((dt) => {
                                                                return { name: dt.text, value: dt.count }
                                                            })}
                                                        cx="50%"
                                                        cy="50%"
                                                        labelLine={false}
                                                        label={renderCustomizedLabel}
                                                        outerRadius={100}
                                                        dataKey="value"
                                                    >
                                                        {data.result_val.map((entry, index) => (
                                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                        ))}
                                                    </Pie>
                                                </PieChart>
                                            </ResponsiveContainer>
                                        </div>

                                        <div>
                                            {
                                                data.polltype === "multipleOption" ? (
                                                    <div>
                                                        {data.result_val.map((dt, ind) => {
                                                            return (
                                                                <div>
                                                                    <div className='flex justify-between items-center mt-3'>
                                                                        <p>{dt.text}</p>
                                                                        <p>{(((dt.count / data.totalcount) * 100).toFixed(1)) + "% (" + (dt.count + " votes)")}</p>
                                                                    </div>
                                                                    <div className='mt-2 p-2 relative rounded-md w-full bg-border2'>
                                                                        <div className='absolute top-0 rounded-md left-0 h-full' style={{ width: (((dt.count / data.totalcount) * 100) + "%"), backgroundColor: COLORS[ind % COLORS.length] }}></div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                ) : (
                                                    <div className='grid grid-cols-2 gap-6'>
                                                        {data.result_val.map((dt, ind) => {
                                                            return (
                                                                <div className='border relative border-border2 rounded cursor-pointer '>
                                                                    <div className='flex justify-center items-center p-1'>
                                                                        <img src={dt.image} className='h-44' alt="" />
                                                                    </div>
                                                                    <div
                                                                        className='p-2 border-t border-border2 '
                                                                    >
                                                                        <div className='flex justify-center items-center'>
                                                                            <p className='text-base'>{dt.text}</p>
                                                                        </div>
                                                                    </div>

                                                                    <div className='absolute right-0 top-0'>
                                                                        <div className='bg-border2 text-sm p-2 rounded-md z-10 opacity-90'>
                                                                            <p>{(((dt.count / data.totalcount) * 100).toFixed(1)) + "% (" + (dt.count + " votes)")}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className='absolute w-full rounded-md bottom-0 left-0 opacity-50' style={{ height: (((dt.count / data.totalcount) * 100) + "%"), backgroundColor: COLORS[ind % COLORS.length] }}></div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                )
                                            }

                                        </div>
                                    </div>
                                ) : (
                                    <div className='flex items-center justify-center my-32'>
                                        <h1 className='text-3xl'>
                                            No one responed yet
                                        </h1>
                                    </div>
                                )}

                            </div>

                            <div>

                                <div>
                                    <h1 className='text-2xl mt-6'>Share</h1>
                                </div>
                                <div
                                    className='relative mt-2'
                                >
                                    <p
                                        className='bg-inputBackground overflow-hidden text-text1 rounded-md pl-4 py-3 text-base outline-none w-full pr-12 border border-transparent focus:border focus:border-indigo-600'
                                        style={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                                    >
                                        {window.location.href.split('/')[0] + window.location.href.split('/')[1] + '//' + window.location.href.split('/')[2] + '/poll/' + window.location.href.split('/')[4]}
                                    </p>

                                    <div
                                        className="absolute cursor-pointer inset-y-0 right-5 pl-3 flex items-center "
                                        onClick={() => {
                                            navigator.clipboard.writeText(window.location.href.split('/')[0] + window.location.href.split('/')[1] + '//' + window.location.href.split('/')[2] + '/poll/' + window.location.href.split('/')[4])
                                            setCopied(true)
                                        }}
                                    >
                                        {copied ? <p className='text-text1'>Copied</p> : <ClipboardIcon />}
                                    </div>
                                </div>

                                <div className='flex gap-2 justify-center items-center mt-4'>
                                    <a href={("https://api.whatsapp.com/send?text=" + URLShare)}>
                                        <WhatsappIcon />
                                    </a>
                                    <a href={("https://www.facebook.com/sharer.php?u=" + URLShareLink)}>
                                        <FacebookIcon />
                                    </a>
                                    <a href={('https://twitter.com/share?url=' + URLShare)}>
                                        <TwitterIcon />
                                    </a>
                                    <a href={("https://telegram.me/share/url?url=" + URLShare)}>
                                        <TelegramIcon />
                                    </a>
                                    <a href={('https://reddit.com/submit?url=' + URLShareLink)}>
                                        <RedditIcon />
                                    </a>
                                </div>
                                
                                <div className='flex gap-4 items-center justify-center mt-8 mb-6'>
                                    <button
                                        className='py-2 px-16 mt-6 bg-indigo-600 rounded-md'
                                        onClick={() => navigate('/create')}
                                    >
                                        Create New Poll
                                    </button>
                                </div>

                                <div id='ad2' className='mt-6'>
                                    <div id="M829850ScriptRootC1357848">
                                    </div>
                                    {/* <script src="https://jsc.adskeeper.com/b/e/bestkaun.com.1357848.js" async>
                            </script> */}
                                </div>

                                

                                {/* <div>
                            <h1 className='text-2xl mt-6'>Latest polls</h1>
                            <div>
                                {latest5.map((latest) => {
                                    return (
                                        <div
                                            className='mt-4 cursor-pointer'
                                            onClick={() => {
                                                window.location.href = `${window.location.href.split('/')[0]}${window.location.href.split('/')[1]}//${window.location.href.split('/')[2]}/poll/${latest._id}`
                                            }}
                                        >
                                            <p
                                                className='bg-inputBackground overflow-hidden rounded-md pl-4 py-3 text-base outline-none pr-12 border border-transparent'
                                                style={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                                            >
                                                {latest.title}
                                            </p>
                                        </div>
                                    )
                                })}
                            </div>
                        </div> */}

                            </div>

                        </div>
                        <div>
                            <Footer />
                        </div>
                    </div>
                </>
            )}
        </>
    )
}

export default Results
