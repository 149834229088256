import React from 'react'
import { MailIcon, WebsiteURLIcon } from '../icons'
import img1 from "./../images/make_india_logo.png"
import img2 from "./../images/logoWithBackground.png"

const Footer = () => {
  return (
    <div className='pt-48'>
      <div className='absolute bottom-0 left-0 bg-background2 p-4 mt-16 w-full text-text1'>
        <div className='p-4 flex gap-8 flex-col lg:flex-row'>
          <div className='w-full lg:w-1/2 gap-4 flex flex-col sm:flex-row'>
            <div>

              <h2 className='text-xl font-bold'>Contact info</h2>
              <div className='flex gap-2 items-center mt-2'>
                <WebsiteURLIcon />
                <a className='text-blue-400 hover:underline' href="https://bestkaun.com/">https://bestkaun.com</a>
              </div>

              <div className='flex gap-2 items-center mt-2'>
                <MailIcon />
                <p>mongodb.bestkaun@gmail.com</p>
              </div>
            </div>
            <div className='flex items-center gap-2'>
              <div>
                <img className='h-12' src={img2} alt="" />
              </div>
              <div>
                <img className='h-12' src={img1} alt="" />
              </div>

            </div>


          </div>

          <div className='w-full lg:w-1/2'>
            <h2 className='text-xl font-bold'>More info</h2>
            <p><a className='text-blue-400 hover:underline' href="https://bestkaun.com/">bestkaun.com</a> website is India's well-known survey website. It does not store user data or location. <a className='text-blue-400 hover:underline' href="/policy.html">Privacy policy</a> </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
