import React from 'react'
import { Link } from 'react-router-dom'

const Error = () => {
    return (
        <div>
            <div className='mt-36 text-center'>
                <h1 className='text-8xl'>404</h1>
                <h1 className='text-5xl'>Page No Found</h1>
                <p className='text-blue-500 mt-6 hover:text-text1'>
                    <Link to="/">Go to Home </Link>
                </p>
            </div>
        </div>
    )
}

export default Error