import React, { useEffect, useState } from 'react'
import { CheckIcon, CrossIcon, DownArrowIcon, ImageIcon, LoaderIcon, PlusIcon } from '../icons'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import img from "../images/logoWithBackground.png"
import Footer from './footer';
import CryptoJS from 'crypto-js';

function CreatePoll() {
    const [descVis, setDescVis] = useState(false);
    const [showDropdown1, setShowDropdown1] = useState(false);
    // const [showDropdown2, setShowDropdown2] = useState(false);
    const [showDropdown3, setShowDropdown3] = useState(false);
    const [pollType, setPollType] = useState("multipleOption");
    const [lastOption, setLastOption] = useState(false);
    const [lastImageOption, setLastImageOption] = useState(false);
    // const [multipleSelect, setMultipleSelect] = useState(false);
    const [error, setError] = useState("");
    const [load, setLoad] = useState(false);
    // const [latest5, setLatest5] = useState([]);

    const navigate = useNavigate()

    const [data, setData] = useState({
        title: "",
        description: "",
        imgtitle: "",
        options: ["", ""],
        polltype: "multipleOption",
        multipleoption: { "type": "None" },
        setenddate: null,
        imageoptions: [{ image: "", text: "Option 1" }, { image: "", text: "Option 2" }],
        votingrestiction: "One vote per IP address",
        allowcomments: false
    })

    const handleOption = (i, val) => {
        var temp = { ...data };
        temp.options[i] = val;
        setData(temp);
    }

    const handleImageOptionText = (i, val) => {
        var temp = { ...data };
        temp.imageoptions[i].text = val;
        setData(temp);
    }

    const handleRemoveOption = (i) => {
        var temp = { ...data };
        temp.options.splice(i, 1);
        setData(temp);
        if (data.options.length === 1) {
            setLastOption(true);
        } else {
            setLastOption(false);
        }
    }

    const handleRemoveImageOption = (i) => {
        var temp = { ...data };
        temp.imageoptions.splice(i, 1);
        setData(temp);
        if (data.imageoptions.length === 1) {
            setLastImageOption(true);
        } else {
            setLastImageOption(false);
        }
    }

    const addOption = () => {
        setData({ ...data, options: [...data.options, ""] })
        setLastOption(false)
    }

    const addImageOption = () => {
        setData({ ...data, imageoptions: [...data.imageoptions, { image: "", text: ("Option " + (data.imageoptions.length + 1)) }] })
        setLastImageOption(false)
    }

    const handleImage = (i, file) => {
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
            var temp = { ...data };
            temp.imageoptions[i].image = fileReader.result;
            setData(temp);
        };
        fileReader.readAsDataURL(file, "UTF-8");
    }

    // const handleMultipleOptions = () => {
    //     setMultipleSelect(!multipleSelect);
    //     if(data.multipleoption.type !== "None"){
    //         setData({ ...data, multipleoption: { type: "None" } })    
    //     } else {
    //         setData({ ...data, multipleoption: { type: "unlimited" } })
    //     }
    // }

    // useEffect(() => {
    //     axios.post("https://backend.bestkaun.com/poll/latest/", {})
    //         .then((res) => {
    //             if (res.data == null) {
    //                 navigate('/');
    //             }
    //             setLatest5(res.data);
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //         })
    // }, [navigate])


    const handleSubmit = () => {
        if (data.title === "") {
            setError("Please provide Title");
            return;
        }
        if (pollType === "multipleOption") {
            if (data.options.length < 2) {
                setError("Please provide more than one options");
                return;
            } else if (data.options.length > 10) {
                setError("Please provide less than 10 options");
                return
            } else if ((new Set(data.options)).size !== data.options.length) {
                setError("Please provide unique options");
                return;
            } else if (data.multipleoption.type === "Exact number") {
                if (data.number > data.options.length) {
                    setError("Please provide number less than or equal to number of options");
                    return;
                }
            } else if (data.multipleoption.type === "range") {
                if (data.startnumber > data.options.length || data.startnumber < 0 || data.endnumber > data.options.length || data.endnumber < 0) {
                    setError("Please provide number less than or equal to number of options");
                    return;
                }
            } else {
                var f = 0;
                for (var i = 0; i < data.options.length; i++) {
                    if (data.options[i] === "") {
                        f = 1;
                        break
                    }
                }
                if (f === 1) {
                    setError("Don't provide blank option.");
                    return
                }
            }
        }
        if (pollType === "imagePoll") {
            if (data.imageoptions.length < 2) {
                setError("Please provide more than one options");
                return;
            } else if (data.imageoptions.length > 10) {
                setError("Please provide less than 10 options");
                return
            } else if (data.multipleoption.type === "Exact number") {
                if (data.number > data.imageoptions.length) {
                    setError("Please provide number less than or equal to number of options");
                    return;
                }
            } else if (data.multipleoption.type === "range") {
                if (data.startnumber > data.imageoptions.length || data.startnumber < 0 || data.endnumber > data.imageoptions.length || data.endnumber < 0) {
                    setError("Please provide number less than or equal to number of options");
                    return;
                }
            } else {
                var arr = (data.imageoptions.map((dt) => dt.text))
                if ((new Set(arr)).size !== arr.length) {
                    setError("Please provide unique options");
                    return;
                } else {
                    var flg = false;
                    for (let i = 0; i < data.imageoptions.length; i++) {
                        if (data.imageoptions[i].image === "") {
                            flg = true;
                            break
                        }
                    }
                    if (flg) {
                        setError("Please provide images in all the option");
                        return;
                    }
                }
                f = 0;
                for (i = 0; i < data.imageoptions.length; i++) {
                    if (data.imageoptions[i].text === "") {
                        f = 1;
                        break
                    }
                }
                if (f === 1) {
                    setError("Don't provide blank option.");
                    return
                }
            }
        }
        if (data.setenddate != null) {
            var currDate = new Date();
            var temp = data.setenddate.split('/');
            var eDate = new Date(temp[0], temp[1] - 1, temp[2], temp[3], temp[4]);
            if (currDate >= eDate) {
                setError("Please provide date after current date");
                return;
            }
        }
        setLoad(true);

        var key = process.env.REACT_APP_KEY
        key = CryptoJS.enc.Utf8.parse(key);
        var encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), key, {mode: CryptoJS.mode.ECB});
        encrypted =encrypted.toString();
        axios.post("https://backend.bestkaun.com/poll/creation", { ...data , key: encrypted })
            .then((res) => {
                var id = res.data._id;
                setLoad(false);
                navigate('/poll/' + id);
            })
            .catch((err) => {
                setLoad(false);
                navigate('/')
            })

    }


    useEffect(() => {
        const script2 = document.createElement('script');
        script2.src = "https://jsc.adskeeper.com/b/e/bestkaun.com.1357848.js";
        script2.async = true;
        // script.type = "text/jsx";
        script2.defer = true;

        let ad2 = document.getElementById('ad2');
        ad2.appendChild(script2);

        // return (() => {
        //     document.getElementById('ad').removeChild(script);
        // })
    }, [])
    return (
        <>
            <div>
                <div className='w-full md:w-1/2 mx-auto mt-6 '>
                    <div className='flex items-center justify-center mb-4 cursor-pointer'>
                        <div className='w-20 h-20' onClick={() => navigate('/')}>
                            <img src={img} alt="" />
                        </div>
                    </div>
                    <div className='text-center'>
                        <h1 className="text-2xl font-bold leading-7 sm:text-3xl sm:truncate text-white">
                            Create a Poll
                        </h1>
                        <p className="mt-2 text-sm text-gray-500">
                            Complete the below fields to create your poll.
                        </p>
                    </div>

                    <div className='mt-6'>
                        <div className='bg-background2 border-t-4 border-border1 rounded p-6'>
                            <div>
                                <div>
                                    <h3 className='text-sm'> Title </h3>
                                    <div
                                        className='relative mt-2'
                                    >
                                        <input
                                            className='bg-inputBackground rounded-md pl-4 py-3 text-base outline-none w-full pr-12 border border-transparent focus:border focus:border-indigo-600'
                                            type="text"
                                            placeholder='Type your question here'
                                            onChange={(e) => setData({ ...data, title: e.target.value })}
                                        />

                                        <div className="absolute inset-y-0 right-5 pl-3 flex items-center ">
                                            <label className="bg-inputBackground cursor-pointer rounded-md flex flex-col items-center justify-center text-sm text-text1">
                                                <ImageIcon />
                                                <input type="file" onChange={e => {
                                                    const fileReader = new FileReader();
                                                    fileReader.onload = (e) => {
                                                        setData({ ...data, imgtitle: fileReader.result });
                                                    };
                                                    fileReader.readAsDataURL(e.target.files[0], "UTF-8");
                                                }}
                                                    accept='image/*'
                                                />
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    {descVis ? (
                                        <>
                                            <div className='mt-6'>
                                                <div>
                                                    <h3 className='text-sm'> Description <span className='text-gray-500'>(optional)</span> </h3>
                                                    <div
                                                        className='mt-2'
                                                    >
                                                        <textarea
                                                            className='bg-inputBackground rounded-md pl-4 py-3 text-base outline-none w-full pr-6 border border-transparent focus:border focus:border-indigo-600'
                                                            type="text"
                                                            placeholder='Type your question here'
                                                            rows={4}
                                                            onChange={(e) => setData({ ...data, description: e.target.value })}
                                                        ></textarea>
                                                        <div
                                                            className='text-sm mt-1 text-gray-400 text-right hover:text-gray-500 cursor-pointer'
                                                            onClick={() => setDescVis(false)}
                                                        >
                                                            <p className=''>Hide description</p>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div
                                                className='text-sm mt-1 text-gray-400 hover:text-gray-500 cursor-pointer flex gap-1 items-center'
                                                onClick={() => setDescVis(true)}
                                            >
                                                <PlusIcon />
                                                <p className=''>Add description</p>
                                            </div>
                                        </>
                                    )}
                                </div>

                                <div>
                                    {data.imgtitle ? (
                                        <div className='mt-6'>
                                            <div className='cursor-pointer' onClick={() => setData({ ...data, imgtitle: "" })}>
                                                <CrossIcon />
                                            </div>
                                            <img src={data.imgtitle} className='w-36' alt="" />
                                        </div>
                                    ) : <></>}
                                </div>

                                <div className='mt-6'>
                                    <h3 className='text-sm'> Voting type </h3>
                                    <div
                                        className="cursor-pointer relative mt-2"
                                        onClick={() => setShowDropdown1(!showDropdown1)}
                                        onMouseLeave={() => setShowDropdown1(false)}
                                    >
                                        <div
                                            className="flex justify-between textInput font-normal text-base"
                                        >
                                            {
                                                pollType === "multipleOption" ? (
                                                    <div className='p-3 bg-inputBackground w-full md:w-1/2 rounded-md flex justify-between items-center'>
                                                        <div>
                                                            <p>Multiple Choice</p>
                                                        </div>
                                                        <DownArrowIcon />
                                                    </div>
                                                ) : (
                                                    <div className='p-3 bg-inputBackground w-full md:w-1/2 rounded-md flex justify-between items-center'>
                                                        <div>
                                                            <p>Image Poll</p>
                                                        </div>
                                                        <DownArrowIcon />
                                                    </div>
                                                )
                                            }
                                        </div>
                                        <div>
                                            {showDropdown1 ? (
                                                <div className='absolute z-10 w-full md:w-1/2 bg-background2 border py-1 border-border2 rounded-md'>
                                                    <ul>
                                                        <div
                                                            className='flex justify-between items-center px-3 py-2 hover:bg-indigo-600'
                                                            onClick={() => {
                                                                setData({ ...data, polltype: "multipleOption" })
                                                                setPollType("multipleOption")
                                                            }}
                                                        >
                                                            <p>Multiple Choice</p>
                                                            {
                                                                pollType === "multipleOption" ? (
                                                                    <CheckIcon />
                                                                ) : (
                                                                    <></>
                                                                )
                                                            }
                                                        </div>
                                                        <div
                                                            className='flex justify-between items-center px-3 py-2 hover:bg-indigo-600'
                                                            onClick={() => {
                                                                setData({ ...data, polltype: "imagePoll" })
                                                                setPollType("imagePoll")
                                                            }}
                                                        >
                                                            <p>Image Poll</p>
                                                            {
                                                                pollType === "imagePoll" ? (
                                                                    <CheckIcon />
                                                                ) : (
                                                                    <></>
                                                                )
                                                            }
                                                        </div>
                                                    </ul>
                                                </div>
                                            ) : <></>}
                                        </div>
                                    </div>
                                </div>


                                <div className='mt-6'>

                                    <h3 className='text-sm'> Answer Options </h3>
                                    {
                                        pollType === 'multipleOption' ?
                                            <div>
                                                <div>
                                                    {data.options.map((option, ind) => {
                                                        return (
                                                            <div>
                                                                {/* <div className='flex justify-between items-center mt-2 bg-inputBackground rounded-md p-3 text-base outline-none w-full border border-transparent focus:border focus:border-indigo-600'>
                                                        <p className=''>{ option }</p>
                                                        <div>
                                                            <CrossIcon />
                                                        </div>
                                                    </div> */}

                                                                <div
                                                                    className='relative mt-2'
                                                                >
                                                                    <input
                                                                        className='bg-inputBackground rounded-md pl-4 py-3 text-base outline-none w-full pr-12 border border-transparent focus:border focus:border-indigo-600'
                                                                        type="text"
                                                                        value={option}
                                                                        onChange={(e) => handleOption(ind, e.target.value)}
                                                                        placeholder={"Option " + (ind + 1)}
                                                                    />

                                                                    <div
                                                                        className="absolute cursor-pointer z-10 inset-y-0 right-5 pl-3 flex items-center "
                                                                        onClick={() => handleRemoveOption(ind)}
                                                                    >
                                                                        {!lastOption ?
                                                                            <CrossIcon />
                                                                            :
                                                                            <></>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>

                                                <div className='mt-2'>
                                                    <div
                                                        className='flex gap-2 cursor-pointer items-center py-2 px-3 bg-inputBackground rounded-md w-fit'
                                                        onClick={addOption}
                                                    >
                                                        <PlusIcon />
                                                        <p>Add option</p>
                                                    </div>
                                                </div>
                                            </div>
                                            :

                                            <div>
                                                <div className='grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4 mt-2'>
                                                    {data.imageoptions.map((option, ind) => {
                                                        return (
                                                            <div>
                                                                <div className='border border-border2 p-1 rounded-md relative'>
                                                                    <div
                                                                        className="absolute cursor-pointer right-2 top-2 "
                                                                        onClick={() => handleRemoveImageOption(ind)}
                                                                    >
                                                                        {!lastImageOption ?
                                                                            <CrossIcon />
                                                                            :
                                                                            <></>
                                                                        }
                                                                    </div>
                                                                    {option.image ?
                                                                        <img className='w-full' style={{ height: "138px" }} src={option.image} alt="" />
                                                                        :
                                                                        <div>
                                                                            <label className="bg-inputBackground cursor-pointer py-12 rounded-md flex flex-col items-center justify-center text-sm text-text1">
                                                                                <ImageIcon />
                                                                                <input type="file" accept='image/*' onChange={e => handleImage(ind, e.target.files[0])} />
                                                                                Click here to upload
                                                                            </label>
                                                                        </div>
                                                                    }

                                                                    <input
                                                                        className='mt-2 text-sm bg-inputBackground w-full rounded-md px-2 py-2 outline-none border border-transparent focus:border focus:border-indigo-600'
                                                                        type="text"
                                                                        value={option.text}
                                                                        onChange={(e) => handleImageOptionText(ind, e.target.value)}
                                                                        placeholder={"Label " + (ind + 1) + " (optional)"}
                                                                    />

                                                                </div>

                                                            </div>
                                                        )
                                                    })}

                                                    <div
                                                        className='border border-border2 p-1 rounded-md cursor-pointer'
                                                        onClick={addImageOption}
                                                    >
                                                        <div className='py-16 flex flex-col items-center justify-center'>
                                                            <PlusIcon />
                                                            <p className='text-sm text-text1'>Add option</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    }


                                    {/* <div
                                        className='mt-2'
                                    >
                                        <input
                                            className='bg-inputBackground rounded-md pl-4 py-3 text-base outline-none w-full pr-6 border border-transparent focus:border focus:border-indigo-600'
                                            type="text"
                                            placeholder={"Option " + (data.options.length + 1)}
                                        />
                                    </div> */}

                                </div>

                                <div className='mt-8 border-t border-b pb-6 border-border2'>
                                    <h3 className='text-base mt-4'> Settings </h3>
                                    {/* <div>

                                    <div
                                        className='mt-6 flex justify-between w-full md:w-1/2 items-center'
                                        onClick={handleMultipleOptions}
                                    >
                                        <p className='text-sm'>Allow selection of multiple options</p>
                                        {multipleSelect ? <ActiveRadioButton /> : <InactiveRadioButton />}
                                    </div>

                                    <div>
                                        {multipleSelect ?
                                            <div>
                                                <div
                                                    className="cursor-pointer relative mt-2"
                                                    onClick={() => setShowDropdown2(!showDropdown2)}
                                                    onMouseLeave={() => setShowDropdown2(false)}
                                                >
                                                    <div
                                                        className="flex justify-between textInput font-normal text-base"
                                                    >
                                                        {
                                                            data.multipleoption.type === "unlimited" ? (
                                                                <div className='p-3 bg-inputBackground w-full md:w-1/2 rounded-md flex justify-between items-center'>
                                                                    <div>
                                                                        <p>Unlimited</p>
                                                                    </div>
                                                                    <DownArrowIcon />
                                                                </div>
                                                            ) : (data.multipleoption.type === "Exact number" ? (
                                                                <div className='p-3 bg-inputBackground w-full md:w-1/2 rounded-md flex justify-between items-center'>
                                                                    <div>
                                                                        <p>Exact number</p>
                                                                    </div>
                                                                    <DownArrowIcon />
                                                                </div>
                                                            ) : (
                                                                <div className='p-3 bg-inputBackground w-full md:w-1/2 rounded-md flex justify-between items-center'>
                                                                    <div>
                                                                        <p>Range</p>
                                                                    </div>
                                                                    <DownArrowIcon />
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                    <div>
                                                        {showDropdown2 ? (
                                                            <div className='absolute z-10 w-full md:w-1/2 bg-background2 border py-1 border-border2 rounded-md'>
                                                                <ul>
                                                                    <div
                                                                        className='flex justify-between items-center px-3 py-2 hover:bg-indigo-600'
                                                                        onClick={() => setData({ ...data, multipleoption: { ...data.multipleoption, type: "unlimited" } })}
                                                                    >
                                                                        <p>Unlimited</p>
                                                                        {
                                                                            data.multipleoption.type === "unlimited" ? (
                                                                                <CheckIcon />
                                                                            ) : (
                                                                                <></>
                                                                            )
                                                                        }
                                                                    </div>
                                                                    <div
                                                                        className='flex justify-between items-center px-3 py-2 hover:bg-indigo-600'
                                                                        onClick={() => setData({ ...data, multipleoption: { ...data.multiple, type: "Exact number" } })}
                                                                    >
                                                                        <p>Exact number</p>
                                                                        {
                                                                            data.multipleoption.type === "Exact number" ? (
                                                                                <CheckIcon />
                                                                            ) : (
                                                                                <></>
                                                                            )
                                                                        }
                                                                    </div>
                                                                    <div
                                                                        className='flex justify-between items-center px-3 py-2 hover:bg-indigo-600'
                                                                        onClick={() => setData({ ...data, multipleoption: { ...data.multipleoption, type: "range" } })}
                                                                    >
                                                                        <p>Range</p>
                                                                        {
                                                                            data.multipleoption.type === "range" ? (
                                                                                <CheckIcon />
                                                                            ) : (
                                                                                <></>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </ul>
                                                            </div>
                                                        ) : <></>}
                                                    </div>
                                                </div>


                                                <div>
                                                    {data.multipleoption.type === "Exact number" ?
                                                        <div>
                                                            <input
                                                                className='mt-2 text-sm bg-inputBackground w-full md:w-1/2 rounded-md px-2 py-2 outline-none border border-transparent focus:border focus:border-indigo-600'
                                                                type="Number"
                                                                min="0"
                                                                max={pollType === "multipleOption" ? data.options.length : data.imageoptions.length}
                                                                onChange={(e) => setData({ ...data, number: e.target.value })}
                                                                placeholder='Number'
                                                            />
                                                        </div>
                                                        :
                                                        (data.multipleoption.type === "range" ?
                                                            <div className='flex gap-3 '>
                                                                <input
                                                                    className='mt-2 text-sm bg-inputBackground w-2/12 rounded-md px-2 py-2 outline-none border border-transparent focus:border focus:border-indigo-600'
                                                                    type="Number"
                                                                    min="0"
                                                                    max={pollType === "multipleOption" ? data.options.length : data.imageoptions.length}
                                                                    onChange={(e) => setData({ ...data, startnumber: e.target.value })}
                                                                    placeholder='Minimum'
                                                                />
                                                                <input
                                                                    className='mt-2 text-sm bg-inputBackground w-2/12 rounded-md px-2 py-2 outline-none border border-transparent focus:border focus:border-indigo-600'
                                                                    type="Number"
                                                                    min="0"
                                                                    max={pollType === "multipleOption" ? data.options.length : data.imageoptions.length}
                                                                    onChange={(e) => setData({ ...data, endnumber: e.target.value })}
                                                                    placeholder='Maximum'
                                                                />
                                                            </div>
                                                            :
                                                            <></>
                                                        )
                                                    }
                                                </div>

                                            </div>
                                            :
                                            <></>
                                        }
                                    </div>
                                </div> */}

                                    {/* <div>
                                        <div
                                            className='mt-8 flex justify-between w-full md:w-1/2 items-center'
                                            onClick={() => {
                                                setEndDate(!endDate);
                                                setData({ ...data, setenddate: null })
                                            }}
                                        >
                                            <p className='text-sm'>Set end date</p>
                                            {endDate ? <ActiveRadioButton /> : <InactiveRadioButton />}
                                        </div>

                                        <div>
                                            {endDate ? (
                                                <div>
                                                    <input
                                                        className='mt-2 text-sm bg-inputBackground w-full md:w-1/2 rounded-md px-2 py-2 outline-none border border-transparent focus:border focus:border-indigo-600'
                                                        type="datetime-local"
                                                        onChange={(e) => setData({ ...data, setenddate: `${e.target.value.split('T')[0].split('-')[0]}/${e.target.value.split('T')[0].split('-')[1]}/${e.target.value.split('T')[0].split('-')[2]}/${e.target.value.split('T')[1].split(':')[0]}/${e.target.value.split('T')[1].split(':')[1]}/00` })}
                                                    />
                                                </div>
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                    </div> */}

                                    <div>
                                        <div
                                            className='mt-8'
                                        >
                                            <p className='text-sm'>Voting restrictions</p>

                                            <div
                                                className="cursor-pointer relative mt-2"
                                                onClick={() => setShowDropdown3(!showDropdown3)}
                                                onMouseLeave={() => setShowDropdown3(false)}
                                            >
                                                <div
                                                    className="flex justify-between textInput font-normal text-base"
                                                >
                                                    <div className='p-3 bg-inputBackground w-full md:w-1/2 rounded-md flex justify-between items-center'>
                                                        <div>
                                                            <p>{data.votingrestiction}</p>
                                                        </div>
                                                        <DownArrowIcon />
                                                    </div>
                                                </div>
                                                <div>
                                                    {showDropdown3 ? (
                                                        <div className='absolute w-full md:w-1/2 bg-background2 border py-1 border-border2 rounded-md'>
                                                            <ul>
                                                                <div
                                                                    className='flex justify-between items-center px-3 py-2 hover:bg-indigo-600'
                                                                    onClick={() => setData({ ...data, votingrestiction: "unlimited votes per user" })}
                                                                >
                                                                    <p>unlimited votes per user</p>
                                                                    {
                                                                        data.votingrestiction === "unlimited votes per user" ? (
                                                                            <CheckIcon />
                                                                        ) : (
                                                                            <></>
                                                                        )
                                                                    }
                                                                </div>
                                                                <div
                                                                    className='flex justify-between items-center px-3 py-2 hover:bg-indigo-600'
                                                                    onClick={() => setData({ ...data, votingrestiction: "One vote browser session" })}
                                                                >
                                                                    <p>One vote browser session</p>
                                                                    {
                                                                        data.votingrestiction === "One vote browser session" ? (
                                                                            <CheckIcon />
                                                                        ) : (
                                                                            <></>
                                                                        )
                                                                    }
                                                                </div>
                                                                <div
                                                                    className='flex justify-between items-center px-3 py-2 hover:bg-indigo-600'
                                                                    onClick={() => setData({ ...data, votingrestiction: "One vote per IP address" })}
                                                                >
                                                                    <p>One vote per IP address</p>
                                                                    {
                                                                        data.votingrestiction === "One vote per IP address" ? (
                                                                            <CheckIcon />
                                                                        ) : (
                                                                            <></>
                                                                        )
                                                                    }
                                                                </div>
                                                            </ul>
                                                        </div>
                                                    ) : <></>}
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    {/* <div
                                    className='mt-8 flex justify-between w-full md:w-1/2 items-center'
                                    onClick={() => {
                                        setData({ ...data, allowcomments: !data.allowcomments })
                                    }}
                                >
                                    <p className='text-sm'>Allow comments</p>
                                    {data.allowcomments ? <ActiveRadioButton /> : <InactiveRadioButton />}
                                </div> */}
                                </div>

                                <div className='mt-4 text-base text-red-400'>
                                    <h3>{error}</h3>
                                </div>

                                <div className='flex gap-4 items-center'>
                                    <button
                                        className='py-2 px-16 mt-6 bg-indigo-600 rounded-md'
                                        onClick={handleSubmit}
                                        disabled={load}
                                    >
                                        {load ? <div className='w-8 h-8'> <LoaderIcon /> </div> : <>Create Poll</>}
                                    </button>
                                </div>

                            </div>


                        </div>

                        <div id='ad2' className='mt-6'>
                            <div id="M829850ScriptRootC1357848">
                            </div>
                            {/* <script src="https://jsc.adskeeper.com/b/e/bestkaun.com.1357848.js" async>
                            </script> */}
                        </div>

                        {/* <div>
                        <h1 className='text-2xl mt-6'>Latest polls</h1>
                        <div>
                            {latest5.map((latest) => {
                                return (
                                    <div
                                        className='mt-4 cursor-pointer'
                                        onClick={() => {
                                            window.location.href = `${window.location.href.split('/')[0]}${window.location.href.split('/')[1]}//${window.location.href.split('/')[2]}/poll/${latest._id}`
                                        }}
                                    >
                                        <p
                                            className='bg-inputBackground overflow-hidden rounded-md pl-4 py-3 text-base outline-none pr-12 border border-transparent'
                                            style={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                                        >
                                            {latest.title}
                                        </p>
                                    </div>
                                )
                            })}
                        </div>
                    </div> */}


                    </div>
                </div >
                <div>
                    <Footer />
                </div>
            </div>
        </>
    )
}

export default CreatePoll
